import type { NitroFetchRequest, $Fetch } from 'nitropack';
import type {
  UserPlacement,
  PositionAndPredicitonsResponse,
  UsersPredictionsResponse,
  UserPrediction,
  NewUserPredictionResponse
} from '~/interfaces';

export const challengeRepository = <T>(fetch: $Fetch<T, NitroFetchRequest>) => ({
  async createPrediction(game: string, odd: number) {
    const body: any = { game, odd };

    const response = await fetch<NewUserPredictionResponse>('/api/predictions', {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response;
  },

  async updateGameStatus(gameId: number, status: string) {
    const body: any = { status };

    const response = await fetch<UserPrediction>(`/api/predictions/${gameId}/status`, {
      method: 'PUT',
      body: body,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response;
  },

  async getWeeklyLeaderboard(usernameSearch: string = '') {
    const response = await fetch<UserPlacement[]>(
      `/api/predictions/leaderboard/weekly?usernameSearch=${usernameSearch}`
    );
    return response;
  },

  async getMonthlyLeaderboard(usernameSearch: string = '') {
    const response = await fetch<UserPlacement[]>(
      `/api/predictions/leaderboard/monthly?usernameSearch=${usernameSearch}`
    );
    return response;
  },

  async getUsersChallengePositionsAndPredictionsNumber(userId: string) {
    const response = await fetch<PositionAndPredicitonsResponse>(
      `/api/predictions/position/${userId}`
    );
    return response;
  },

  async getUsersPredictionsByPeriod(
    userId: string,
    period: string = '',
    page: number = 1,
    limit?: number
  ) {
    const response = await fetch<UsersPredictionsResponse>(
      `/api/predictions/${userId}?period=${period}&page=${page}${limit ? `&limit=${limit}` : ''}`
    );
    return response;
  },
  async deletePrediction(predictionId: number) {
    await fetch(`/api/predictions/${predictionId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },
  async getAllPredictionsWithPagination(page: number = 1, limit: number = 20) {
    const response = await fetch<UsersPredictionsResponse>(
      `/api/predictions?page=${page}&limit=${limit}`
    );
    return response;
  }
});
